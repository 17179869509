//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "SearchBar",
    data() {
        return {
            searchText: ''
        }
    },
    props:[
        "isDark"
    ],
    methods: {
        search() {
            if (this.searchText == '') {
                this.$q.notify({
                    message: 'Please enter a search text to search across the community.'
                });
            }
            this.$router.push({ path: '/search', query: { q: this.searchText } });
        }
    }
}
